import { MainToolbarTitle } from '@newageerp/v3.bundles.layout-bundle';
import { axiosInstance } from '@newageerp/v3.bundles.utils-bundle';
import { useParams } from '@newageerp/v3.templates.templates-core';
import React, { Fragment, useEffect, useState } from 'react'
import { DashboardGrossCustomConfig } from './types';
import { MP } from '@newageerp/crm-ui'
import DashboardGrossCustomData from './DashboardGrossCustomData';
import { useDataCache } from '@newageerp/v3.app.data-cache-provider';

export default function DashboardGrossCustom() {
    const { system: dsSystem } = useDataCache();
    const [config, setConfig] = useState<DashboardGrossCustomConfig>();
    const routeParams = useParams<{ type: string }>();

    const loadConfig = async () => {
        if (routeParams.type) {
            const res = await axiosInstance.post('/app/nae-core/sf-config/getUserConfig', { config: 'gross-performance' })
            if (!!res.data && !!res.data.success) {
                const data = res.data.data.find((el: any) => el.type === routeParams.type);

                if (data) {
                    setConfig(data);
                }
            }
        }
    }
    useEffect(() => {
        setConfig(undefined);
        loadConfig().catch(console.error);
    }, [routeParams.type])

    if (!config) {
        return <Fragment />
    }

    return (
        <Fragment>
            <MainToolbarTitle title={`Gross - ${config.title}`} />

            <MP.GlobalProvider
                settings={{
                    apiKey: dsSystem.data.settings.mp.apiKey,
                    baseUrl: dsSystem.data.settings.mp.baseUrl,
                }}
            >

                <DashboardGrossCustomData config={config} />
            </MP.GlobalProvider>
        </Fragment>
    )
}