import { Compact, CompactRow, FieldDateRange, FieldLabel } from '@newageerp/v3.bundles.form-bundle'
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import React, { Fragment, useEffect, useState } from 'react'
import LanguageFilter from './Components/LanguageFilter';
import FunnelFilter from './Components/FunnelFilter';
import AngleFilter from './Components/AngleFilter';
import GenderFilter from './Components/GenderFilter';
import CountryFilter, { CountryFilterCountry } from './Components/CountryFilter';
import CountryGroupFilter, { CountryGroupFilterGroup } from './Components/CountryGroupFilter';
import PlanFilter from './Components/PlanFilter';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import moment from 'moment';
import { useLocalStorage, useUList, useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { MainToolbarTitle, Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle';

export default function DashboardRefundCounts() {
  const t = (e: string) => e;
  
  const [lang, setLang] = useState('');
  const [funnels, setFunnels] = useLocalStorage('DashboardFunnelFilter', []);
  const [angle, setAngle] = useState('');
  const [gender, setGender] = useState('');
  const [countries, setCountries] = useState<string[]>([]);
  const [countryGroup, setCountryGroup] = useState(0);
  const [plan, setPlan] = useState('');

  const [stats, setStats] = useState<any[]>([]);
  // const [periods, setPeriods] = useState([]);

  const defDates = {
    dateFrom: '2022-12-01',
    dateTo: moment().add(1, 'day').format("YYYY-MM-DD")
  };
  const [chartDates, setChartDates] = useState(defDates);
  const clearState = () => {
    setChartDates(defDates)
  }
  const [countriesReq, countriesData] = useUList<CountryFilterCountry>("country", ['id', 'name']);
  const [countryGroupsReq, countryGroupsData] = useUList<CountryGroupFilterGroup>("country-group", ['id', 'name', 'countries', 'countriesExclude']);
  useEffect(() => {
    countriesReq(
      {},
      1,
      9999,
      [{ key: 'i.name', value: 'ASC' }]
    );
    countryGroupsReq(
      {},
      1,
      9999,
      [{ key: 'i.name', value: 'ASC' }]
    );
  }, []);

  const [reqData, reqDataParams] = useURequest("/app/plugins/ProductPerformance/refundsCountData");
  const loadData = () => {
    reqData({
      dateFrom: chartDates.dateFrom,
      dateTo: chartDates.dateTo,
      lang,
      funnels,
      countries,
      plan,
      gender,
      angle,
    }).then((res: any) => {
      setStats(res.data.stats);
      // setPeriods(res.data.periods);
    })
  }

  const borderLeft = 'border-l border-blue-400';
  const borderRight = 'border-r border-blue-400';

  return (
    <Fragment>
      <MainToolbarTitle title='Refunds & chargeback stats' />
      <div className='space-y-4'>
        <WhiteCard isCompact={true} className='space-y-2'>
          <div className='flex flex-wrap gap-x-4 gap-y-1 items-end'>
            <Compact>
              <CompactRow
                label={<FieldLabel>{t('Initial sales date')}</FieldLabel>}
                control={
                  <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                }
              />
            </Compact>

            <LanguageFilter
              lang={lang}
              setLang={setLang}
            />

            <FunnelFilter
              funnels={funnels}
              setFunnels={setFunnels}
            />

            <AngleFilter
              angle={angle}
              setAngle={setAngle}
            />

            <GenderFilter
              gender={gender}
              setGender={setGender}
            />

            <CountryFilter
              countries={countries}
              setCountries={setCountries}
              countriesData={countriesData.data.data}
            />

            <CountryGroupFilter
              countryGroup={countryGroup}
              setCountryGroup={setCountryGroup}
              countryGroupsData={countryGroupsData.data.data}

              setCountries={setCountries}
              countriesData={countriesData.data.data}
            />

            <PlanFilter
              plan={plan}
              setPlan={setPlan}
            />
          </div>

          <div>
            <MainButton color={"sky"} iconName='filter' disabled={reqDataParams.loading} onClick={loadData}>Filter</MainButton>
          </div>
        </WhiteCard>

        <WhiteCard title='Refunds + Chargeback'>
          <Table
            thead={
              <thead>
                <tr>
                  <Th >Period</Th>
                  <Th className={borderRight} textAlignment='text-right'>Subscriptions</Th>
                  
                  <Th textAlignment='text-right'>Refund at least one</Th>
                  <Th className={borderRight} textAlignment='text-right'>Ratio</Th>

                  <Th textAlignment='text-right'>Refund 1</Th>
                  <Th className={borderRight} textAlignment='text-right'>Ratio</Th>

                  <Th textAlignment='text-right'>Refund 2</Th>
                  <Th className={borderRight} textAlignment='text-right'>Ratio</Th>

                  <Th textAlignment='text-right'>Refund 3</Th>
                  <Th className={borderRight} textAlignment='text-right'>Ratio</Th>

                  <Th textAlignment='text-right'>Refund 4</Th>
                  <Th className={borderRight} textAlignment='text-right'>Ratio</Th>

                  <Th textAlignment='text-right'>Refund 5</Th>
                  <Th className={borderRight} textAlignment='text-right'>Ratio</Th>

                  <Th textAlignment='text-right'>Refund 6+</Th>
                  <Th className={borderRight} textAlignment='text-right'>Ratio</Th>

                  <Th textAlignment='text-right'>Buy upsell</Th>
                  <Th textAlignment='text-right'>Refund upsell</Th>
                  <Th className={borderRight} textAlignment='text-right'>Ratio</Th>

                  <Th textAlignment='text-right'>Sub payments</Th>
                  <Th textAlignment='text-right'>Refund payments</Th>
                  <Th className={borderRight} textAlignment='text-right'>Ratio</Th>
                </tr>
              </thead>
            }
            tbody={
              <tbody>
                {stats.map(el => {
                  const subRefundRate = `${(el.hasRefunds / el.subs * 100).toFixed(2)}%`;
                  const upsellRefundRate = el.hasUpsell > 0 ? `${(el.refundUpsell / el.hasUpsell * 100).toFixed(2)}%` : '-';

                  const subsPaymentsRefundRate = el.subsPaymentsCount > 0 ? `${(el.subsRefundsCount / el.subsPaymentsCount * 100).toFixed(2)}%` : '-';

                  const subRefund1Rate = `${(el.hasRefunds1 / el.hasRefunds * 100).toFixed(2)}%`;
                  const subRefund2Rate = `${(el.hasRefunds2 / el.hasRefunds * 100).toFixed(2)}%`;
                  const subRefund3Rate = `${(el.hasRefunds3 / el.hasRefunds * 100).toFixed(2)}%`;
                  const subRefund4Rate = `${(el.hasRefunds4 / el.hasRefunds * 100).toFixed(2)}%`;
                  const subRefund5Rate = `${(el.hasRefunds5 / el.hasRefunds * 100).toFixed(2)}%`;
                  const subRefund6Rate = `${(el.hasRefunds6 / el.hasRefunds * 100).toFixed(2)}%`;

                  return (
                    <tr key={`r-${el.period}`}>
                      <Td className='whitespace-nowrap'>{el.period}</Td>
                      <Td className={borderRight} textAlignment='text-right'>{el.subs.toFixed(0)}</Td>
                      
                      <Td textAlignment='text-right'>{el.hasRefunds.toFixed(0)}</Td>
                      <Td className={borderRight} textAlignment='text-right'>{subRefundRate}</Td>

                      <Td textAlignment='text-right'>{el.hasRefunds1.toFixed(0)}</Td>
                      <Td className={borderRight} textAlignment='text-right'>{subRefund1Rate}</Td>

                      <Td textAlignment='text-right'>{el.hasRefunds2.toFixed(0)}</Td>
                      <Td className={borderRight} textAlignment='text-right'>{subRefund2Rate}</Td>

                      <Td textAlignment='text-right'>{el.hasRefunds3.toFixed(0)}</Td>
                      <Td className={borderRight} textAlignment='text-right'>{subRefund3Rate}</Td>

                      <Td textAlignment='text-right'>{el.hasRefunds4.toFixed(0)}</Td>
                      <Td className={borderRight} textAlignment='text-right'>{subRefund4Rate}</Td>

                      <Td textAlignment='text-right'>{el.hasRefunds5.toFixed(0)}</Td>
                      <Td className={borderRight} textAlignment='text-right'>{subRefund5Rate}</Td>

                      <Td textAlignment='text-right'>{el.hasRefunds6.toFixed(0)}</Td>
                      <Td className={borderRight} textAlignment='text-right'>{subRefund6Rate}</Td>

                      <Td textAlignment='text-right'>{el.hasUpsell.toFixed(0)}</Td>
                      <Td textAlignment='text-right'>{el.refundUpsell.toFixed(0)}</Td>
                      <Td className={borderRight} textAlignment='text-right'>{upsellRefundRate}</Td>

                      <Td textAlignment='text-right'>{el.subsPaymentsCount.toFixed(0)}</Td>
                      <Td textAlignment='text-right'>{el.subsRefundsCount.toFixed(0)}</Td>
                      <Td className={borderRight} textAlignment='text-right'>{subsPaymentsRefundRate}</Td>
                    </tr>
                  )
                })}
              </tbody>
            }
          />
        </WhiteCard>
      </div>

    </Fragment>
  )
}
