import React, { Fragment } from 'react'
import ReportItem from './Components/ReportItem'
import { MP } from '@newageerp/crm-ui';
import { useDataCache } from '@newageerp/v3.app.data-cache-provider';

type Props = {
    id: number
    dataSource: string
    element: any
}

export default function ResolverElementContentRight(props: Props) {
    const { system: dsSystem } = useDataCache();
    return (
        <MP.GlobalProvider settings={{
            apiKey: dsSystem.data.settings.mp.apiKey,
            baseUrl: dsSystem.data.settings.mp.baseUrl,
        }}>

            <Fragment>
                {props.dataSource === 'report-item' && <ReportItem id={props.id} />}
            </Fragment>
        </MP.GlobalProvider>
    )
}
