import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { Compact, CompactRow, FieldDate, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle';
import { useLocalStorage } from '@newageerp/v3.bundles.hooks-bundle';
import { MainToolbarTitle, Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle'
import { groupMap } from '@newageerp/v3.bundles.utils-bundle';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react'

export default function DashboardRealtime() {
    const t = (e: string) => e;
    
    const [data, setData] = useState([]);
    const [lastReload, setLastReload] = useState('');
    const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));

    const [showHours, setShowHours] = useState('0');
    const [showMedium, setShowMedium] = useLocalStorage('CRM_realtime_show_medium', '0');

    const [sortStr, setSortStr] = useLocalStorage('CRM_realtime_sort', 'purchase');

    const [groupBy, setGroupBy] = useLocalStorage('CRM_realtime_utm_source_field', 'utm_source');

    const loadData = async () => {
        const res = await fetch(
            'https://an.todayistheday.app/api/stats-crm',
            {
                method: 'POST',
                body: JSON.stringify({ date: currentDate }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        const resJson = await res.json();
        setData(resJson.data);

        setLastReload(moment().format('YYYY-MM-DD HH:mm'));
    }

    useEffect(() => {
        loadData();
    }, [currentDate]);

    useEffect(() => {
        const t = setInterval(() => {
            // loadData();
            loadData();
        }, 60 * 5 * 1000);
        return () => {
            clearInterval(t);
        }
    }, [currentDate]);

    const groupedByHours = groupMap(data, (el) => el.hourString);
    const hours = Object.keys(groupedByHours).sort().reverse();

    return (
        <Fragment>
            <MainToolbarTitle title={lastReload} />

            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-4 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDate value={currentDate} onChange={setCurrentDate} />
                                }
                                className='w-56'
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Group by')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={groupBy}
                                        onChange={setGroupBy}
                                        options={[
                                            {
                                                label: "UTM source",
                                                value: "utm_source",
                                            },
                                            {
                                                label: "UTM source / medium",
                                                value: "utm_source_medium",
                                            },
                                            {
                                                label: "UTM source / medium / campaign",
                                                value: "utm_source_medium_campaign",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Show hours')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={showHours}
                                        onChange={setShowHours}
                                        options={[
                                            {
                                                label: "No",
                                                value: "0",
                                            },
                                            {
                                                label: "Yes",
                                                value: "10",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Secondary')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={showMedium}
                                        onChange={setShowMedium}
                                        options={[
                                            {
                                                label: "No",
                                                value: "0",
                                            },
                                            {
                                                label: "Medium",
                                                value: "10",
                                            },
                                            {
                                                label: "Campaign",
                                                value: "20",
                                            },
                                            {
                                                label: "Keyword",
                                                value: "30",
                                            },
                                            {
                                                label: "Ad content",
                                                value: "40",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Sort')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={sortStr}
                                        onChange={setSortStr}
                                        options={[
                                            {
                                                label: "Purchase",
                                                value: "purchase",
                                            },
                                            {
                                                label: "CR",
                                                value: "cr",
                                            },

                                        ]}
                                    />
                                }
                            />
                        </Compact>
                        <div>
                            <MainButton onClick={loadData} iconName='repeat' color='sky'>
                                Refresh
                            </MainButton>
                        </div>
                    </div>
                </WhiteCard>
                {showHours === '10' ? <Fragment>
                    {hours.map(h => {
                        return <WhiteCard title={h} key={`h-${h}`}>
                            <ParseDataByUtmSource
                                data={groupedByHours[h]}
                                showMedium={showMedium === '10'}
                                showCampaign={showMedium === '20'}
                                showKeyword={showMedium === '30'}
                                showAdContent={showMedium === '40'}
                                showExtra={showMedium !== '0'}
                                groupBy={groupBy}
                                sortStr={sortStr}
                            />
                        </WhiteCard>
                    })}
                </Fragment> :
                    <WhiteCard>
                        <ParseDataByUtmSource
                            data={data}
                            showMedium={showMedium === '10'}
                            showCampaign={showMedium === '20'}
                            showKeyword={showMedium === '30'}
                            showAdContent={showMedium === '40'}
                            showExtra={showMedium !== '0'}
                            groupBy={groupBy}
                            sortStr={sortStr}
                        />
                    </WhiteCard>
                }

            </div>
        </Fragment>
    )
}

const borderClassName = 'border-t border-sky-400';

type ParseDataByUtmSourceProps = {
    data: any[],
    showMedium: boolean,
    showCampaign: boolean,
    showKeyword: boolean,
    showAdContent: boolean,
    showExtra: boolean,
    groupBy: string,

    sortStr: string,
}

const calcDataByType = (data: any[], type: string, key: string = 'eventDistinct') => {
    const total = data.filter(el => el.type === type).map(el => parseInt(el[key], 10)).reduce((a, b) => a + b, 0);
    return total;
}

const ParseDataByUtmSource = (props: ParseDataByUtmSourceProps) => {
    const groupedData = groupMap(props.data, (el) => {
        if (props.groupBy === 'utm_source_medium') {
            return `${el.utm_source} / ${el.utm_medium}`;
        } if (props.groupBy === 'utm_source_medium_campaign') {
            return `${el.utm_source} / ${el.utm_medium} / ${el.utm_campaign}`;
        } else {
            return el[props.groupBy];
        }
    });
    const utmSources = Object.keys(groupedData).sort((a, b) => {
        if (props.sortStr === 'cr') {
            const pViewA = calcDataByType(groupedData[a], 'PageView');
            const pViewB = calcDataByType(groupedData[b], 'PageView');

            const t1 = pViewA > 0 ? calcDataByType(groupedData[a], 'Purchase') / pViewA : pViewA;
            const t2 = pViewB > 0 ? calcDataByType(groupedData[b], 'Purchase') / pViewB : pViewB;
            if (t1 > t2) {
                return -1;
            }
            if (t1 < t2) {
                return 1;
            }
            return 0;
        }

        const t1 = calcDataByType(groupedData[a], 'Purchase');
        const t2 = calcDataByType(groupedData[b], 'Purchase');
        if (t1 > t2) {
            return -1;
        }
        if (t1 < t2) {
            return 1;
        }
        return 0;
    });

    return <div>
        <Table
            thead={
                <thead>
                    <tr>
                        <Th>Source</Th>
                        {props.showMedium && <Th>Medium</Th>}
                        {props.showCampaign && <Th>Campaign</Th>}
                        {props.showKeyword && <Th>Keyword</Th>}
                        {props.showAdContent && <Th>Ad Content</Th>}
                        <Th textAlignment='text-right'>Landing</Th>
                        <Th textAlignment='text-right'>Start quiz</Th>
                        <Th textAlignment='text-right'>Results page</Th>
                        <Th textAlignment='text-right'>Left email</Th>
                        <Th textAlignment='text-right'>Checkout</Th>
                        <Th textAlignment='text-right'>Purchase</Th>
                        <Th textAlignment='text-right'>CR</Th>
                    </tr>
                </thead>
            }
            tbody={
                <tbody>

                    <tr className='total-row font-bold'>
                        <Td></Td>
                        {props.showExtra && <Td></Td>}
                        <Td textAlignment='text-right'>
                            {calcDataByType(props.data, 'PageView')}
                        </Td>
                        <Td textAlignment='text-right'>
                            {calcDataByType(props.data, 'Start quiz')}
                        </Td>
                        <Td textAlignment='text-right'>
                            {calcDataByType(props.data, 'Results page')}
                        </Td>
                        <Td textAlignment='text-right'>
                            {calcDataByType(props.data, 'LeftEmail')}
                        </Td>
                        <Td textAlignment='text-right'>
                            {calcDataByType(props.data, 'Checkout')}
                        </Td>
                        <Td textAlignment='text-right'>
                            {calcDataByType(props.data, 'Purchase')}
                        </Td>
                        <Td textAlignment='text-right'>
                            {((calcDataByType(props.data, 'Purchase') / calcDataByType(props.data, 'PageView')) * 100).toFixed(2)}
                        </Td>
                    </tr>

                    {utmSources.map((utmSource) => {
                        const utmSourceData = groupedData[utmSource];
                        const groupedDataMedium = groupMap(utmSourceData, (el) => {
                            if (props.showMedium) {
                                return el.utm_medium;
                            }
                            if (props.showKeyword) {
                                return el.utm_keyword;
                            }
                            if (props.showAdContent) {
                                return el.utm_content;
                            }
                            return el.utm_campaign
                        });
                        const utmMediums = Object.keys(groupedDataMedium).sort((a, b) => {
                            if (props.sortStr === 'cr') {
                                const pViewA = calcDataByType(groupedDataMedium[a], 'PageView');
                                const pViewB = calcDataByType(groupedDataMedium[b], 'PageView');
                    
                                const t1 = pViewA > 0 ? calcDataByType(groupedDataMedium[a], 'Purchase') / pViewA : pViewA;
                                const t2 = pViewB > 0 ? calcDataByType(groupedDataMedium[b], 'Purchase') / pViewB : pViewB;
                                if (t1 > t2) {
                                    return -1;
                                }
                                if (t1 < t2) {
                                    return 1;
                                }
                                return 0;
                            }
                    
                            const t1 = calcDataByType(groupedDataMedium[a], 'Purchase');
                            const t2 = calcDataByType(groupedDataMedium[b], 'Purchase');
                            if (t1 > t2) {
                                return -1;
                            }
                            if (t1 < t2) {
                                return 1;
                            }
                            return 0;
                        });;

                        let _borderClassName = !props.showExtra ? '' : borderClassName;

                        return (
                            <Fragment key={`utm-source-${utmSource}`}>
                                <tr className={classNames({ 'font-medium': props.showExtra })}>
                                    <Td className={_borderClassName} rowSpan={props.showExtra ? utmMediums.length + 1 : undefined}>{utmSource}</Td>
                                    {/* {props.showMedium && <Td>Total</Td>} */}
                                    {!props.showExtra &&
                                        <Fragment>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmSourceData, 'PageView')}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmSourceData, 'Start quiz')}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmSourceData, 'Results page')}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmSourceData, 'LeftEmail')}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmSourceData, 'Checkout')}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmSourceData, 'Purchase')}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {((calcDataByType(utmSourceData, 'Purchase') / calcDataByType(utmSourceData, 'PageView')) * 100).toFixed(2)}
                                            </Td>
                                        </Fragment>
                                    }
                                </tr>
                                {props.showExtra && <Fragment>
                                    {utmMediums.map((utmMedium, idx) => {
                                        let _borderClassName = idx > 0 ? '' : borderClassName;

                                        const utmMediumData = groupedDataMedium[utmMedium];

                                        return <tr key={`utm-source-${utmSource}-${utmMedium}`}>
                                            <Td className={_borderClassName}>{utmMedium}</Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmMediumData, 'PageView')}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmMediumData, 'Start quiz')}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmMediumData, 'Results page')}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmMediumData, 'LeftEmail')}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmMediumData, 'Checkout')}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmMediumData, 'Purchase')}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {((calcDataByType(utmMediumData, 'Purchase') / calcDataByType(utmMediumData, 'PageView')) * 100).toFixed(2)}
                                            </Td>
                                        </tr>
                                    })}
                                </Fragment>}

                            </Fragment>
                        )
                    })}
                </tbody>
            }
        />
    </div>
}
