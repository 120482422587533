import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import { Compact, CompactRow, FieldDateRange, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle';

import { LogoLoader, MainToolbarTitle, Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle';
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';

import { useURequestRaw } from '@newageerp/v3.bundles.hooks-bundle';

export default function DashboardPlanPerformance() {
    const t = (e: string) => e;
    
    const defDates = {
        dateFrom: '2022-12-01',
        dateTo: moment().add(1, 'day').format("YYYY-MM-DD")
    };
    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    const [reqData, reqDataParams] = useURequestRaw("/app/plugins/PlanPerformance/calcPlanPerformance");
    const loadData = () => {
        reqData({
            dateFrom: chartDates.dateFrom,
            dateTo: chartDates.dateTo,
        }).then((res: any) => {
            
        })
    }


    return (
        <Fragment>
            <MainToolbarTitle title={"Plan performance"} />
            <div className='space-y-4'>
                <WhiteCard isCompact={true} className='space-y-2'>
                    <div className='flex flex-wrap gap-x-4 gap-y-1 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>

                       

                    </div>
                    
                    <div>
                        <MainButton color={"sky"} iconName='filter' disabled={reqDataParams.loading} onClick={loadData}>Filter</MainButton>
                    </div>
                </WhiteCard>

                {reqDataParams.loading && <LogoLoader size={60} />}

            </div>
        </Fragment>
    )
}
