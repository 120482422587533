import React, { useEffect } from "react";
import { TemplatesCoreProvider, TemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { templatesComponentsMap } from "@newageerp/v3.templates.templates-components-map"
import { MainBundle, INaeProperty } from "@newageerp/v3.app.main-bundle";
import '@newageerp/v3.app.main-bundle/dist/main-bundle.css'

import { PluginsMap } from "../../../Plugins/PluginsMap";

import { NaeSProperties } from "../../_custom/config/NaeSProperties";
import ResolverListToolbarLeft from "../../../Plugins/Resolvers/ListToolbar/Left/ResolverListToolbarLeft";
import ResolverElementToolbarMoreMenu from "../../../Plugins/Resolvers/ElementToolbar/MoreMenu/ResolverElementToolbarMoreMenu";
import ResolverElementToolbarAfterFields from "../../../Plugins/Resolvers/ElementToolbar/AfterFields/ResolverElementToolbarAfterFields";
import ResolverElementContentRight from "../../../Plugins/Resolvers/ElementContent/Right/ResolverElementContentRight";
import ResolverEditFormCustomField from "../../../Plugins/Resolvers/EditForm/CustomField/ResolverEditFormCustomField";
import ResolverViewFormCustomField from "../../../Plugins/Resolvers/ViewForm/CustomField/ResolverViewFormCustomField";
import ResolverListRowCustomField from "../../../Plugins/Resolvers/ListRow/CustomField/ResolverListRowCustomField";
import { DataCacheSchema } from "../../../Plugins/DataCache/data-cache-fields";
import { DataCachePreload } from "../../../Plugins/DataCache/data-cache-preload";


function App() {
    useEffect(() => {
        window.document.title = `Loading...`;
    }, []);

    return (
        <MainBundle>
            <TemplatesCoreProvider
                resolvers={{
                    'window.list.toolbar': {
                        left: [
                            (opt: any) => <ResolverListToolbarLeft dataSource={opt.dataSource} />
                        ]
                    },
                    'window.element.toolbar': {
                        moreMenu: [
                            (opt: any) => <ResolverElementToolbarMoreMenu dataSource={opt.dataSource} id={opt.id} element={opt.element} />
                        ],
                        afterFields: [
                            (opt: any) => <ResolverElementToolbarAfterFields dataSource={opt.dataSource} id={opt.id} element={opt.element} />
                        ]
                    },
                    'window.element.content': {
                        right: [
                            (opt: any) => <ResolverElementContentRight dataSource={opt.dataSource} id={opt.id} element={opt.element} />
                        ]
                    },
                    'window.edit.form': {
                        customField: [
                            (opt: any) => <ResolverEditFormCustomField {...opt} />
                        ]
                    },
                    'window.view.form': {
                        customField: [
                            (opt: any) => <ResolverViewFormCustomField {...opt} />
                        ]
                    },
                    'window.list.row': {
                        customField: [
                            (opt: any) => <ResolverListRowCustomField {...opt} />
                        ]
                    }
                }}
                templatesMap={templatesComponentsMap}
                pluginsMap={PluginsMap}
                dataCache={{
                    cacheData: DataCachePreload,
                    fields: DataCacheSchema,
                }}
                modules={{
                    properties: {
                        
                    },
                }}
            >
                <TemplatesLoader templateName="App" />
            </TemplatesCoreProvider>
        </MainBundle>
    );
}

export default App;
