import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { Compact, CompactRow, FieldDate, FieldDateRange, FieldInput, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle';
import { useLocalStorage, useUList } from '@newageerp/v3.bundles.hooks-bundle';
import { MainToolbarTitle, Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle'
import { groupMap } from '@newageerp/v3.bundles.utils-bundle';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react'
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import CountryFilter, { CountryFilterCountry } from './Components/CountryFilter';
import FunnelFilter from './Components/FunnelFilter';
import { checkPermission, Permissions } from '../Permissions/Permissions';
import { useDataCache } from '@newageerp/v3.app.data-cache-provider';

const trimEllip = function (str: string, length: number) {
    return str.length > length ? str.substring(0, length) + "..." : str;
}

export default function DashboardRealtimeV2() {
    const { system: dsSystem } = useDataCache();
    const { settings } = dsSystem.data;
    
    const t = (e: string) => e;
    
    const { userState } = useTemplatesCore();
    const isTiktokUser = checkPermission(userState, Permissions.Enum.tiktok);
    const isGoogleUser = checkPermission(userState, Permissions.Enum.google);

    const [data, setData] = useState([]);
    const [lastReload, setLastReload] = useState('');

    const defDates = {
        dateFrom: moment().format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD")
    };

    const [countries, setCountries] = useLocalStorage('CRM_realtime_country', []);
    const [countriesReq, countriesData] = useUList<CountryFilterCountry>("country", ['id', 'name']);
    const [funnels, setFunnels] = useLocalStorage('DashboardFunnelFilter', []);

    useEffect(() => {
        countriesReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
    }, []);

    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    const [search, setSearch] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [showHours, setShowHours] = useState('0');
    const [showMedium, setShowMedium] = useLocalStorage('CRM_realtime_show_medium', '0');

    const [sortStr, setSortStr] = useLocalStorage('CRM_realtime_sort', 'purchase');

    const [groupBy, setGroupBy] = useLocalStorage('CRM_realtime_utm_source_field', 'utm_source');

    const loadData = async () => {
        if (isLoading) return false;
        setIsLoading(true);

        let statsUrl = 'https://an.apidata.app/api/crmStats';
        if (isTiktokUser) {
            statsUrl = 'https://an.apidata.app/api/tiktokStats';
        }
        if (isGoogleUser) {
            statsUrl = 'https://an.apidata.app/api/googleStats';
        }

        try {
            const res = await fetch(
                statsUrl,
                {
                    method: 'POST',
                    body: JSON.stringify({ dateFrom: chartDates.dateFrom, dateTo: chartDates.dateTo, project: settings.analytics.project, countries: countries.map((c: string) => `'${c}'`), funnels: funnels.map((c: string) => `'${c}'`) }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            const resJson = await res.json();
            setData(resJson.data ? resJson.data : []);

            setLastReload(moment().format('YYYY-MM-DD HH:mm'));

            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [chartDates]);

    useEffect(() => {
        const t = setInterval(() => {
            // loadData();
            loadData();
        }, 60 * 5 * 1000);
        return () => {
            clearInterval(t);
        }
    }, [chartDates]);

    const groupedByHours = groupMap(data, (el) => el.hourString);
    const hours = Object.keys(groupedByHours).sort().reverse();

    return (
        <Fragment>
            <MainToolbarTitle title={lastReload} />

            <div className='space-y-4'>
                <WhiteCard isCompact={true}>
                    <div className='flex gap-4 items-end flex-wrap'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>
                        <CountryFilter
                            countries={countries}
                            countriesData={countriesData.data.data}
                            setCountries={setCountries}
                        />
                        <FunnelFilter
                            funnels={funnels}
                            setFunnels={setFunnels}
                        />
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Group by')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={groupBy}
                                        onChange={setGroupBy}
                                        options={[
                                            {
                                                label: "UTM source",
                                                value: "utm_source",
                                            },
                                            {
                                                label: "UTM source / medium",
                                                value: "utm_source_medium",
                                            },
                                            {
                                                label: "UTM source / medium / campaign",
                                                value: "utm_source_medium_campaign",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Secondary')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={showMedium}
                                        onChange={setShowMedium}
                                        options={[
                                            {
                                                label: "No",
                                                value: "0",
                                            },
                                            {
                                                label: "Medium",
                                                value: "10",
                                            },
                                            {
                                                label: "Campaign",
                                                value: "20",
                                            },
                                            {
                                                label: "Keyword",
                                                value: "30",
                                            },
                                            {
                                                label: "Ad content",
                                                value: "40",
                                            }
                                        ]}
                                    />
                                }
                            />
                        </Compact>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Sort')}</FieldLabel>}
                                control={
                                    <FieldSelect
                                        value={sortStr}
                                        onChange={setSortStr}
                                        options={[
                                            {
                                                label: "Purchase",
                                                value: "purchase",
                                            },
                                            {
                                                label: "CR",
                                                value: "cr",
                                            },

                                        ]}
                                    />
                                }
                            />
                        </Compact>


                    </div>
                    <div className='flex gap-4 flex-wrap'>
                        <div>
                            <MainButton onClick={loadData} iconName='repeat' color='sky' disabled={isLoading} loading={isLoading}>
                                Refresh
                            </MainButton>
                        </div>
                    </div>
                </WhiteCard>

                <FieldInput
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder='Search'
                    iconName='search'
                    className='w-full'
                />

                {showHours === '10' ? <Fragment>
                    {hours.map(h => {
                        return <WhiteCard title={h} key={`h-${h}`}>
                            <ParseDataByUtmSource
                                data={groupedByHours[h]}
                                showMedium={showMedium === '10'}
                                showCampaign={showMedium === '20'}
                                showKeyword={showMedium === '30'}
                                showAdContent={showMedium === '40'}
                                showExtra={showMedium !== '0'}
                                groupBy={groupBy}
                                sortStr={sortStr}
                                search={search.toLowerCase()}
                            />
                        </WhiteCard>
                    })}
                </Fragment> :
                    <WhiteCard>
                        <ParseDataByUtmSource
                            data={data}
                            showMedium={showMedium === '10'}
                            showCampaign={showMedium === '20'}
                            showKeyword={showMedium === '30'}
                            showAdContent={showMedium === '40'}
                            showExtra={showMedium !== '0'}
                            groupBy={groupBy}
                            sortStr={sortStr}
                            search={search.toLowerCase()}
                        />
                    </WhiteCard>
                }

            </div>
        </Fragment>
    )
}

const borderClassName = 'border-t border-sky-400';

type ParseDataByUtmSourceProps = {
    data: any[],
    showMedium: boolean,
    showCampaign: boolean,
    showKeyword: boolean,
    showAdContent: boolean,
    showExtra: boolean,
    groupBy: string,

    sortStr: string,

    search: string,
}

const calcDataByType = (data: any[], type: string, key: string = 'eventDistinct') => {
    const total = data.filter(el => el.type === type).map(el => parseInt(el[key], 10)).reduce((a, b) => a + b, 0);
    return total;
}

const valByGroupBy = (el: any, groupBy: string): string => {
    if (groupBy === 'utm_source_medium') {
        return `${el.utm_source} / ${el.utm_medium}`;
    } if (groupBy === 'utm_source_medium_campaign') {
        return `${el.utm_source} / ${el.utm_medium} / ${el.utm_campaign}`;
    } else {
        return el[groupBy];
    }
}

const ParseDataByUtmSource = (props: ParseDataByUtmSourceProps) => {
    const { system: dsSystem } = useDataCache();
    const { settings } = dsSystem.data;
    
    const filteredData = props.data.filter(el => {
        if (!props.search) {
            return true;
        }
        const v = valByGroupBy(el, props.groupBy).toLowerCase();
        return v.indexOf(props.search) >= 0;
    });

    const groupedData = groupMap(filteredData, (el) => {
        return valByGroupBy(el, props.groupBy);
    });
    const utmSources = Object.keys(groupedData).sort((a, b) => {
        if (props.sortStr === 'cr') {
            const pViewA = calcDataByType(groupedData[a], settings.analytics.map['PageView']);
            const pViewB = calcDataByType(groupedData[b], settings.analytics.map['PageView']);

            const t1 = pViewA > 0 ? calcDataByType(groupedData[a], settings.analytics.map['Purchase']) / pViewA : pViewA;
            const t2 = pViewB > 0 ? calcDataByType(groupedData[b], settings.analytics.map['Purchase']) / pViewB : pViewB;
            if (t1 > t2) {
                return -1;
            }
            if (t1 < t2) {
                return 1;
            }
            return 0;
        }

        const t1 = calcDataByType(groupedData[a], settings.analytics.map['Purchase']);
        const t2 = calcDataByType(groupedData[b], settings.analytics.map['Purchase']);
        if (t1 > t2) {
            return -1;
        }
        if (t1 < t2) {
            return 1;
        }
        return 0;
    });

    return <div>
        <Table
            thead={
                <thead>
                    <tr>
                        <Th className='max-w-[300px]'>Source</Th>
                        {props.showMedium && <Th className='max-w-[300px]'>Medium</Th>}
                        {props.showCampaign && <Th className='max-w-[300px]'>Campaign</Th>}
                        {props.showKeyword && <Th className='max-w-[300px]'>Keyword</Th>}
                        {props.showAdContent && <Th className='max-w-[300px]'>Ad Content</Th>}
                        <Th textAlignment='text-right'>Advertorial</Th>
                        <Th textAlignment='text-right'>Landing</Th>
                        <Th textAlignment='text-right'>Start quiz</Th>
                        <Th textAlignment='text-right'>Results page</Th>
                        <Th textAlignment='text-right'>Left email</Th>
                        <Th textAlignment='text-right'>Checkout</Th>
                        <Th textAlignment='text-right'>Purchase</Th>
                        <Th textAlignment='text-right'>CR</Th>
                    </tr>
                </thead>
            }
            tbody={
                <tbody>

                    <tr className='total-row font-bold'>
                        <Td></Td>
                        {props.showExtra && <Td></Td>}
                        <Td textAlignment='text-right'>
                            {calcDataByType(filteredData, settings.analytics.map['Advertorial'])}
                        </Td>
                        <Td textAlignment='text-right'>
                            {calcDataByType(filteredData, settings.analytics.map['PageView'])}
                        </Td>
                        <Td textAlignment='text-right'>
                            {calcDataByType(filteredData, settings.analytics.map['Start quiz'])}
                        </Td>
                        <Td textAlignment='text-right'>
                            {calcDataByType(filteredData, settings.analytics.map['Results page'])}
                        </Td>
                        <Td textAlignment='text-right'>
                            {calcDataByType(filteredData, settings.analytics.map['LeftEmail'])}
                        </Td>
                        <Td textAlignment='text-right'>
                            {calcDataByType(filteredData, settings.analytics.map['Checkout'])}
                        </Td>
                        <Td textAlignment='text-right'>
                            {calcDataByType(filteredData, settings.analytics.map['Purchase'])}
                        </Td>
                        <Td textAlignment='text-right'>
                            {((calcDataByType(filteredData, settings.analytics.map['Purchase']) / calcDataByType(filteredData, settings.analytics.map['PageView'])) * 100).toFixed(2)}
                        </Td>
                    </tr>

                    {utmSources.map((utmSource) => {
                        const utmSourceData = groupedData[utmSource];
                        const groupedDataMedium = groupMap(utmSourceData, (el) => {
                            if (props.showMedium) {
                                return el.utm_medium;
                            }
                            if (props.showKeyword) {
                                return el.utm_keyword;
                            }
                            if (props.showAdContent) {
                                return el.utm_content;
                            }
                            return el.utm_campaign
                        });
                        const utmMediums = Object.keys(groupedDataMedium).sort((a, b) => {
                            if (props.sortStr === 'cr') {
                                const pViewA = calcDataByType(groupedDataMedium[a], settings.analytics.map['PageView']);
                                const pViewB = calcDataByType(groupedDataMedium[b], settings.analytics.map['PageView']);

                                const t1 = pViewA > 0 ? calcDataByType(groupedDataMedium[a], settings.analytics.map['Purchase']) / pViewA : pViewA;
                                const t2 = pViewB > 0 ? calcDataByType(groupedDataMedium[b], settings.analytics.map['Purchase']) / pViewB : pViewB;
                                if (t1 > t2) {
                                    return -1;
                                }
                                if (t1 < t2) {
                                    return 1;
                                }
                                return 0;
                            }

                            const t1 = calcDataByType(groupedDataMedium[a], settings.analytics.map['Purchase']);
                            const t2 = calcDataByType(groupedDataMedium[b], settings.analytics.map['Purchase']);
                            if (t1 > t2) {
                                return -1;
                            }
                            if (t1 < t2) {
                                return 1;
                            }
                            return 0;
                        });;

                        let _borderClassName = !props.showExtra ? '' : borderClassName;

                        return (
                            <Fragment key={`utm-source-${utmSource}`}>
                                <tr className={classNames({ 'font-medium': props.showExtra })}>
                                    <Td title={utmSource} className={_borderClassName} rowSpan={props.showExtra ? utmMediums.length + 1 : undefined}>{trimEllip(utmSource, 100)}</Td>
                                    {/* {props.showMedium && <Td>Total</Td>} */}
                                    {!props.showExtra &&
                                        <Fragment>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmSourceData, settings.analytics.map['Advertorial'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmSourceData, settings.analytics.map['PageView'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmSourceData, settings.analytics.map['Start quiz'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmSourceData, settings.analytics.map['Results page'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmSourceData, settings.analytics.map['LeftEmail'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmSourceData, settings.analytics.map['Checkout'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmSourceData, settings.analytics.map['Purchase'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {((calcDataByType(utmSourceData, settings.analytics.map['Purchase']) / calcDataByType(utmSourceData, settings.analytics.map['PageView'])) * 100).toFixed(2)}
                                            </Td>
                                        </Fragment>
                                    }
                                </tr>
                                {props.showExtra && <Fragment>
                                    {utmMediums.map((utmMedium, idx) => {
                                        let _borderClassName = idx > 0 ? '' : borderClassName;

                                        const utmMediumData = groupedDataMedium[utmMedium];

                                        return <tr key={`utm-source-${utmSource}-${utmMedium}`}>
                                            <Td className={_borderClassName}>{utmMedium}</Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmMediumData, settings.analytics.map['Advertorial'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmMediumData, settings.analytics.map['PageView'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmMediumData, settings.analytics.map['Start quiz'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmMediumData, settings.analytics.map['Results page'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmMediumData, settings.analytics.map['LeftEmail'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmMediumData, settings.analytics.map['Checkout'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {calcDataByType(utmMediumData, settings.analytics.map['Purchase'])}
                                            </Td>
                                            <Td className={_borderClassName} textAlignment='text-right'>
                                                {((calcDataByType(utmMediumData, settings.analytics.map['Purchase']) / calcDataByType(utmMediumData, settings.analytics.map['PageView'])) * 100).toFixed(2)}
                                            </Td>
                                        </tr>
                                    })}
                                </Fragment>}

                            </Fragment>
                        )
                    })}
                </tbody>
            }
        />
    </div>
}
