import { Compact, CompactRow, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle'
import React from 'react'

type Props = {
    cohort: string,
    setCohort: (v: string) => void,
}

export default function CohortSettings(props: Props) {
    const t = (e: string) => e;
    
    const { cohort, setCohort } = props;

    return (
        <Compact>
            <CompactRow
                label={<FieldLabel>{t('Cohort')}</FieldLabel>}
                control={
                    <FieldSelect
                        value={cohort}
                        onChange={setCohort}
                        options={[
                            {
                                label: "Monthly",
                                value: "month",
                            },
                            {
                                label: "Weekly",
                                value: "week",
                            }
                        ]}
                    />
                }
            />
        </Compact>
    )
}
