import React, { Fragment } from 'react'
import { MP } from '@newageerp/crm-ui'
import ProductPerformanceChartContainer from './ProductPerformanceChartContainer';
import { MainToolbarTitle } from '@newageerp/v3.bundles.layout-bundle';
import { useDataCache } from '@newageerp/v3.app.data-cache-provider';


export default function ProductPerformanceChart() {
    const { system: dsSystem } = useDataCache();
    const dataSource = 'product-performance-ltv'
    return (
        <Fragment>
            <MainToolbarTitle title={"Weekly cumulative LTV"} />

            <MP.GlobalProvider settings={{
                apiKey: dsSystem.data.settings.mp.apiKey,
                baseUrl: dsSystem.data.settings.mp.baseUrl,
            }}>
                <MP.InfoProvider key={`dt-${dataSource}`} dataSource={dataSource} permissions={{
                    doFilter: false,
                }}
                user='all'>
                    <ProductPerformanceChartContainer />
                </MP.InfoProvider>
            </MP.GlobalProvider>
        </Fragment>
    )
}
