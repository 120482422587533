import { Compact, CompactRow, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle'
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import React, { useEffect } from 'react'

type Props = {
    lang: string,
    setLang: (v: string) => void,
}

export default function LanguageFilter(props: Props) {
    const t = (e: string) => e;
    
    const { lang, setLang } = props;

    const [doReq, reqData] = useURequest<string>("/app/plugins/Reports/ReportsFilter/distinctLanguage");

    useEffect(() => {
        doReq({})
    }, []);

    return (
        <Compact>
            <CompactRow
                label={<FieldLabel>{t('Language')}</FieldLabel>}
                control={
                    <FieldSelect
                        value={lang}
                        onChange={setLang}
                        options={[
                            {
                                label: "All",
                                value: "",
                            },
                            ...reqData.data.data.map(s => {
                                return (
                                    {
                                        label: s.toUpperCase(),
                                        value: s,
                                    }
                                )
                            }),
                        ]}
                    />
                }
            />
        </Compact>
    )
}
