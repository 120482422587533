import { Compact, CompactRow, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle'
import React from 'react'

type Props = {
    plan: string,
    setPlan: (v: string) => void,
}

export default function PlanFilter(props: Props) {
    const t = (e: string) => e;
    
    const { plan, setPlan } = props;

    return (
        <Compact>
            <CompactRow
                label={<FieldLabel>{t('Plan')}</FieldLabel>}
                control={
                    <FieldSelect
                        value={plan}
                        onChange={setPlan}
                        options={[
                            {
                                label: "All",
                                value: "",
                            },
                            {
                                label: "1m",
                                value: "1m",
                            },
                            {
                                label: "3m",
                                value: "3m",
                            },
                            {
                                label: "6m",
                                value: "6m",
                            },
                            // {
                            //     label: "1y",
                            //     value: "1y",
                            // }
                        ]}
                    />
                }
            />
        </Compact>
    )
}
