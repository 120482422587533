import React, { Fragment } from 'react'
import { MenuItemWithSave } from '@newageerp/v3.bundles.modal-bundle'
import { useDs } from '@newageerp/v3.app.data-cache-provider';
import { PropsId } from '../../../_generated/_custom/models-cache-data/types';

export default function Reactivate(props: PropsId) {
    const element = useDs<any>({id: props.id, schema: 'subscription'});

    if (!element) {
        return <Fragment />
    }

    let disabledContent = undefined;
    if (element.active) {
        disabledContent = 'Subscription active';
    }
    if (element.source !== 'manual') {
        disabledContent = 'Only manual subscriptions can be reactivated';
    }

    return (
        <MenuItemWithSave
            elementId={props.id}
            saveData={{
                active: true,
                finishDate: null,
            }}
            schema={"subscription"}
            confirmation={true}
            isDisabled={!!disabledContent}
            tooltipContent={disabledContent}
        >
            Reactivate
        </MenuItemWithSave>
    )
}
