import { Compact, CompactRow, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle'
import React from 'react'

type Props = {
    angle: string,
    setAngle: (v: string) => void,
}

export default function AngleFilter(props: Props) {
    const t = (e: string) => e;
    
    const { angle, setAngle } = props;

    return (
        <Compact>
            <CompactRow
                label={<FieldLabel>{t('Angle')}</FieldLabel>}
                control={
                    <FieldSelect
                        value={angle}
                        onChange={setAngle}
                        options={[
                            {
                                label: "All",
                                value: "",
                            },
                            {
                                label: "Procrastination",
                                value: "procrastination",
                            },
                            {
                                label: "Adhd",
                                value: "adhd",
                            },


                        ]}
                    />
                }
            />
        </Compact>
    )
}
